@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'CustomFont';
  src: url('../public/helvetica_neue.woff2') format('woff2'),
       url('../public/helvetica_neue.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CustomFont';
  src: url('../public/helvetica_neue_bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

* {
  font-family: 'CustomFont', sans-serif;
}

.hero-bg {
  @apply w-screen min-h-screen flex flex-col items-center justify-center overflow-hidden;
  background: url('./images/team.png') no-repeat center center;
  background-size: cover;
  z-index: 0;
}

.hero-content {
  z-index: 1;
  width: 100%;
}


@keyframes wave {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10%);
  }
}

@keyframes wave-main {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(3%);
  }
}

.footer-bg-container {
  position: relative;
  overflow: hidden;
}

.wave-effect {
  position: absolute;
  bottom: -10%;
  left: 0;
  transform: translateX(0);
  animation: ease-in-out wave 5s infinite alternate;
  z-index: 10;
  width: 100%;
  height: 100%;
  
  background-image: url('./images/wave2.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.footer-bg {
  position: absolute;
  bottom: 10%;
  left: 0;
  transform: translateX(0);
  animation: ease-in-out wave-main 5s infinite alternate;
  z-index: 5;
  width: 100%;
  height: 100%;
  background-image: url('./images/footer.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.footer-content {
  position: relative;
  z-index: 20;
  min-height: 250px;
}

@media (max-width: 768px) {
  .footer-bg {
    bottom:25%;
  }
  
  .wave-effect {
    bottom: 0%;
  }
}

.departments-bg {
  @apply w-screen min-h-screen flex items-center;
  background-image: url('./images/departments.png');
  background-size: cover;
  background-position: center;
  background-repeat: repeat-y;
}

.recruitment-bg {
  @apply w-screen h-auto flex flex-col items-center justify-center overflow-hidden;
  background: url('./images/recruitment.png') no-repeat center center;
  background-size: cover;
  background-position: center 60%;
  z-index: -1;
}


@keyframes sailAcross {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(100vw);
  }
}

.loading-animation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
  animation: sailAcross 5s ease-in infinite;
}

.departments-bg video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.departments-bg > * {
  position: relative;
  z-index: 10;
}

.letter {
  display: inline-block;
}

.nowrap {
  white-space:pre-wrap;
  word-break:break-word;
}